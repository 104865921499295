import React, {Suspense } from 'react';
import { HashRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { Spin } from "antd";
import routes from "@/router";

const App = () => {
    return (
        <HashRouter>
            <Suspense fallback={<Spin />}>
                {renderRoutes(routes)}
            </Suspense>
        </HashRouter>
    )
};

export default App;
