import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './views/App';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
const theme = createTheme({
    overrides: {
        MuiSlide: {
            valueLabel: {
                background: "#006987"
            }
        }
    },
    palette: {
        primary: { main: "#017493" },
    }})

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>
  // </React.StrictMode>
);
