import React from 'react';
import { Redirect } from "react-router-dom";
const Login = React.lazy(() => import('../views/login'));
const Index = React.lazy(() => import('../views/index'));
const DataSource = React.lazy(() => import('../views/economic/dataSource'));
const Economic = React.lazy(() => import('../views/economic'));
const Clinical = React.lazy(() => import('../views/clinical'));
const Patient = React.lazy(() => import('../views/patient'));
const Insurance = React.lazy(() => import('../views/insurance'));

export default [
    {
        path: "/",
        exact: true,
        render: () => <Redirect to="/index"/>
    },
    {
        path: "/index",
        title: "首页" ,
        component: Index
    },
    {
        path: "/login",
        title: "登录",
        component: Login
    },
    {
        path: "/clinical",
        title: "关键信息",
        component: Clinical
    },
    {
        path: "/patient",
        title: "患者便利",
        component: Patient
    },
    {
        path: "/economic",
        title: "经济价值",
        component: Economic,
    },
    {
        path: "/insurance",
        title: "疾病负担/药物可及",
        component: Insurance
    },
    {
        path: "/dataSource",
        title: "经济价值",
        component: DataSource
    },

]
